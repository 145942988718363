.home-screen {
  width: 100%;
  height: 100%;
  background-image: linear-gradient(to bottom, #000, #1e1e1e);
}

.home-text {
  margin-top: 175px;
  margin-left: 100px;
  /* width: 125%; */
  color: #b4dad6;
  opacity: 90%;
  transition: transform 1s ease-in-out;
}

.appstore-button {
  display: inline-flex;
  width: 125px;
  /* margin-left: 50px; */
  margin-top: 25px;
  align-items: center;
  justify-content: center;
  border: 2px solid #4a978f;
  border-radius: 10px;
  background-color: transparent;
  padding: 0.625rem 1.5rem;
  text-align: center;
  color: #b4dad6;
  opacity: 100%;
  outline: 0;
  transition: all 0.2s ease;
  text-decoration: none;
  cursor: pointer;
}

.appstore-button:hover {
  background-color: #0b4d46;
  filter: drop-shadow(3px 3px 3px #5db0a8);
  color: white;
  opacity: 100%;
}

.appstore-icon {
  height: 1.5rem;
  width: 1.5rem;
}

.appstore-texts {
  margin-left: 1rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  line-height: 1;
}

.appstore-text-1 {
  font-weight: 600;
  margin-bottom: 0.25rem;
  font-size: 0.75rem;
  line-height: 1rem;
}

.appstore-text-2 {
  font-weight: 800;
}

.playstore-button {
  display: inline-flex;
  /* margin-left: 20px; */
  margin-top: 25px;
  align-items: center;
  justify-content: center;
  border: 2px solid #4a978f;
  border-radius: 10px;
  background-color: transparent;
  padding: 0.625rem 1.5rem;
  text-align: center;
  color: #b4dad6;
  opacity: 100%;
  outline: 0;
  transition: all 0.2s ease;
  text-decoration: none;
  cursor: pointer;
}

.playstore-button:hover {
  background-color: #0b4d46;
  filter: drop-shadow(3px 3px 3px #5db0a8);
  color: white;
  opacity: 100%;
}

.icon {
  height: 1.5rem;
  width: 1.5rem;
}

.texts {
  margin-left: 1rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  line-height: 1;
}

.text-1 {
  font-weight: 600;
  margin-bottom: 0.25rem;
  font-size: 0.75rem;
  line-height: 1rem;
}

.text-2 {
  font-weight: 800;
}

.buttons {
  display: flex;
  margin-top: 40px;
}

.logo {
  height: 100px;
  margin-bottom: 30px;
}

.text {
  font-size: 25px;
  margin-right: 50px;
}

.home-content {
  display: flex;
  height: 100vh;
  margin: 0 30px;
  transition: transform 1.5s ease;
}

.home-text {
  transition: transform 1.5s ease;
}

.home-text.down {
  transform: translateX(-100%);
}

.home-text.up {
  transition-duration: 0.75s;
}

.spline {
  height: 90%;
  /* width: 200%; */
  margin-top: 73px;
  transition: transform 1.5s ease;
}

.home-screen {
  width: 100vw;
  min-height: 100vh;
  background-image: linear-gradient(to bottom, #000, #1e1e1e);
  display: flex;
}

.home-content {
  display: flex;
  padding: 0 20px;
}

.buttons {
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
}

.playstore-button,
.appstore-button {
  margin: 10px;
}


@media screen and (max-width: 900px) {
  .home-content {
    padding: 0 10px;
    flex-direction: column;
  }

  .home-text {
    margin-left: 50px;
    max-width: 85%;
  }

  .logo {
    width: 35%;
    height: auto;
  }

  .spline {
    display: contents;
    height: 70%;
    margin-left: 0;
    margin-top: 0px;
    justify-content: center;
  }

  .text {
    font-size: 25px;
  }

  .buttons{
    flex-wrap: nowrap;
    width: max-content;
  }
}

@media screen and (max-width: 550px) {
  .home-text {
    margin-top: 110px;
    width: fit-content;
    margin-left: 0px;
  }

  .logo {
    width: 150px;
    height: auto;
    margin-top: 25px;
  }

  .text {
    width: 100%;
    font-size: 110%;
  }

  .appstore-button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border: 2px solid #4a978f;
    border-radius: 10px;
    background-color: transparent;
    padding: 0.05rem 0.05rem;
    text-align: center;
    color: #b4dad6;
    opacity: 100%;
    outline: 0;
    transition: all 0.2s ease;
    text-decoration: none;
  }

  .appstore-icon {
    height: 1rem;
    width: 1rem;
  }

  .appstore-texts {
    margin-left: 1rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    line-height: 1;
  }

  .appstore-text-1 {
    font-weight: 600;
    /* margin-bottom: 0.25rem; */
    font-size: 0.5rem;
    line-height: 1rem;
  }

  .appstore-text-2 {
    font-size: 0.75rem;
    font-weight: 800;
    margin-bottom: 3px;
  }

  .playstore-button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border: 2px solid #4a978f;
    border-radius: 10px;
    background-color: transparent;
    padding: 0.05rem 0.75rem;
    text-align: center;
    color: #b4dad6;
    opacity: 100%;
    outline: 0;
    transition: all 0.2s ease;
    text-decoration: none;
  }

  .icon {
    height: 1rem;
    width: 1rem;
    margin-left: 5px;
  }

  .texts {
    margin-left: 1rem;
    display: flex;
    /* flex-direction: ; */
    align-items: flex-start;
    line-height: 1;
  }

  .text-1 {
    font-weight: 600;
    margin-bottom: 0.25rem;
    font-size: 0.5rem;
    line-height: 1rem;
  }

  .text-2 {
    margin-bottom: 5px;
    font-size: 0.75rem;
    font-weight: 800;
  }

  .buttons{
    display: flex;
    flex-direction: row;
  }

  .spline {
    display: none;
  }
  .buttons{
    flex-wrap: nowrap;
    width:max-content ;
  }
}

@media (max-width: 550px) and (min-height: 700px) {
  .spline {
    display: contents;
    height: 10%;
    margin-top: 0px;
    justify-content: center;
  }
}

@media (max-height: 600px) {
  .home-text {
    margin-top: 90px;
    width: fit-content;
    margin-left: 0px;
  }

  .logo {
    width: 200px;
    height: auto;
    margin-top: 25px;
  }

  .text {
    width: 200%;
    font-size: 100%;
  }

  .appstore-button,
  .playstore-button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border: 2px solid #4a978f;
    border-radius: 10px;
    background-color: transparent;
    padding: 0.5rem 0.05rem;
    text-align: center;
    color: #b4dad6;
    opacity: 100%;
    outline: 0;
    transition: all 0.2s ease;
    text-decoration: none;
  }

  .playstore-button{
    padding: 8px 10px;
  }

  .appstore-icon,
  .icon {
    height: 1rem;
    width: 1rem;
  }

  .appstore-texts,
  .texts {
    margin-left: 1rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    line-height: 1;
  }

  .appstore-text-1,
  .text-1 {
    font-weight: 600;
    font-size: 0.5rem;
    line-height: 1rem;
  }

  .appstore-text-2,
  .text-2 {
    font-size: 0.75rem;
    font-weight: 800;
  }

  .buttons {
    display: flex;
    flex-direction: row;
  }
  
  .spline{
    width: 100%;
    margin-left: 550px;
  }

  .buttons {
    flex-wrap: nowrap;
    width: max-content;
  }
}

@media (max-height: 700px) and (max-width: 900px) {
  .home-text {
    /* margin-left: 50px; */
    max-width: 85%;
  }

  .logo {
    width: 50%;
    height: auto;
  }

  .spline {
    display: none;
  }

  .text {
    font-size: 22px;
  }

  .buttons {
    flex-wrap: nowrap;
    width: max-content;
  }
}

@media (min-height: 500px) and (max-height: 800px) and (min-width: 1000px) {
  .home-text {
    margin-left: 50px;
    max-width: 90%;
  }

  .logo {
    width: 50%;
    height: auto;
  }

  .text {
    font-size: 22px;
  }
}

@media (max-width: 700px) and (min-height: 800px) {
  .home-text {
    margin-top: 150px;
    margin-left: 0px;
    max-width: 90%;
  }

  .logo {
    width: 50%;
    height: auto;
  }

  .text {
    font-size: 22px;
  }
}
