.link-text {
  display: flex;
  margin: auto;
  padding: 0 25px;
  color: #b4dad6;
  opacity: 50%;
  font-size: 20px;
}

.navbar {
  position: fixed;
  display: flex;
  width: 90%;
  margin: 0 60px;
  margin-top: 25px;
  justify-content: space-between;
  z-index: 1;
}

.nav-buttons {
  margin-top: 20px;
  margin-bottom: 50px;
  display: flex;
  cursor: pointer;
  position: relative;
}

.kutty-logo {
  width: auto;
  height: 100px;
}

.link-text:hover {
  color: #4a978f;
  opacity: 100%;
}

.link {
  text-decoration: none;
}

.link-text:focus {
  background-color: white;
}

.active {
  color: #000000;
  opacity: 100%;
}

.active-line {
  position: absolute;
  bottom: -5px;
  height: 2px;
  background-color: #4a978f;
  transition: all 0.9s ease-in-out;
}

.active-line-Home {
  width: 70px;
  left: 15px;
}

.active-line-Services {
  width: 80px;
  left: 120px;
}

.active-line-Contact {
  width: 90px;
  left: 235px;
}

.nav-buttons {
  margin-top: 20px;
  margin-bottom: 50px;
  display: flex;
  cursor: pointer;
  position: relative;
}

.burger-menu-icon {
  opacity: 80%;
  color: #4a978f;
}

.mobile-buttons {
  display: inline-flex;
  position: absolute;
  right : 75px;
  margin-top: 15px;
  z-index: 10;
}

.mobile-buttons .link {
  margin: 10px 0;
  text-decoration: none;
}

@media (max-width: 900px) {
  .burger-menu-icon {
    display: flex;
    margin-top: 10px;
    margin-right: 10px;
  }

  .mobile-buttons {
    margin-left: 200px;
  }

  .link-text-mobile {
    font-size: 13px;
    margin-top: 10px;
    padding: 0 10px;
    color: #b4dad6;
    opacity: 50%;
  }
  
}

@media (max-width: 550px) {

  .navbar{
    margin:25px 30px;
  }

  .kutty-logo{
    width: 20%;
    height:20%;
    margin-top: 5px;
  }

  .link-text-mobile {
    padding: 0 10px;
    color: #b4dad6;
    opacity: 50%;
  }
  

  .nav-buttons {
    display: none;
  }

  .burger-menu-icon {
    display: flex;
    margin-top: 10px;
    margin-right: 30px;
  }

  .mobile-buttons {
    margin-left: 0px;
  }

  .mobile-buttons {
    font-size: 12px;
  }
}
