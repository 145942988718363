.services-screen {
  height: 100vh;
  width: 100vw;
  background-image: linear-gradient(to bottom, #000, #1e1e1e);
}

.services-phone {
  margin-top: 150px;
  margin-left: 150px;
  transition: transform 1.5s ease;
}

.services-phone.down{
  transform: translateX(-100%);
}

.services-phone.up{
  transition-duration: 0.75s;
}

.services-content {
  display: flex;
}

.content {
  position: relative;
  background-color: #1d1d1d;
  width: 45%;
  margin: 170px 0 175px 250px; 
  border-radius: 25px;
  transition: transform 1.5s ease, opacity 0.5s ease;
}

.content.up{
  transition-duration: 0.75s;
}

.content.active {
  transform: translateY(0%);
  opacity: 1; 
}

.content.inactive {
  transform: translateY(100%);
  opacity: 0; 
}

.heading {
  margin: 25px 0 0 210px;
  color: #2d7f77;
}

.text-content {
  font-size: 20px;
  color: #b4dad6;
  opacity: 80%;
  margin: 50px 25px 0 25px;
}

.button {
  font-family: ARLRDBD;
  position: relative;
  margin-top: 30px;
  height: 40px;
  width: 140px;
  border-radius: 30px;
  border: 2px solid #2d7f77;
  display: grid;
  top: -300px;
  right: -60px;
  font-size: 14px;
  align-items: center;
  padding-left: 15px;
  padding-right: 10px;
  opacity: 100%;
  color: #7cc6a4;
  cursor: pointer;
  transform: rotate3d(0, 0, 1, -0.3deg);
}

.button-lower {
  transform: rotate3d(0, 0, 1, -1.5deg);
}

.button:hover {
  background-color: #4a978f;
  filter: drop-shadow(1px 1px 1px #5db0a8);
  color: white;
  opacity: 80%;
}

.active {
  background-color: #1D1D1D;
  filter: drop-shadow(3px 3px 3px #1D1D1D);
  color: white;
  opacity: 100%;
}


.active-button {
  background-color: #0b4d46;
  filter: drop-shadow(1px 1px 1px #5db0a8);
  color: white;
  opacity: 100%;
}


.active-button:hover {
  background-color: #0b4d46 !important;
  filter: drop-shadow(1px 1px 1px #5db0a8) !important;
  color: white !important;
  opacity: 100% !important;
}


@media screen and (max-width: 900px) {
  .cards-container-big{
    display: none;
  }

  .services-phone {
    display: none; 
  }

  .services-content {
    display: flex;
    flex-direction: column; 
  }

  .content {
    padding: 5px;
    padding-bottom: 30px;
    width: 90%; 
    margin: 20% auto; 
  }

  .heading {
    margin-left: 31%;
  }

  .text-content {
    text-align: justify;
  }

  .button {
    margin: 20px auto; 
    width: 200px; 
  }
}

@media screen and (max-width: 550px) {
  .cards-container-big{
    display: none;
  }
   
  .services-phone {
    display: none; 
  }

  .services-content {
    padding-top: 13%;
    display: flex;
    flex-direction: column; 
  }

  .content {
    padding : 0.5px;
    width: 90%;
    margin: 20% auto; 
  }

  .text-content{
    margin:25px ;
  }

  .heading{
    font-size: 17px;
    margin-left: 30%;
  }

  .text-content {
    font-size: 13px;
    text-align: justify; 
  }

  .button {
    margin: 20px auto; 
    width: 200px;
  }
}

@media screen and (min-width: 900px) {
  .cards-container {
    display: none;
  }
}


@media screen and (max-height: 600px) {
  .services-screen {
    height: 100vh; 
    background-color: #333; 
  }
}

@media screen and (max-height: 700px) and (max-width: 900px) {
  .content {
    width: 80%; 
  }

  .heading{
    font-size: 20px;
  }

  .content{
    font-size: 15px;
  }
}

@media screen and (min-height: 500px) and (max-height: 700px) and (min-width: 1000px) {
  .phone {
    height: 450px;
  }

  .button{
    height: 25px;
    width: 115px;
    font-size: 11px;
    top:-250px;
    right: -50px;
    padding-left: 5px;
    padding-right: 5px;
  }

  .heading{
    font-size: 25px;
  }

  .content{
    padding-left: 50px;
  }
}

@media screen and (max-width: 700px) and (min-height: 800px) {
  .heading {
    font-size: 25px; 
    margin-left: 30px;
  }
}


