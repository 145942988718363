.popup {
  position: absolute;
  margin-top: 40px;
  border-radius: 25px;
  margin-left: 200px;
  width: 75vw;
  height: 90vh;
  z-index: 2;
  border: 3px solid #1d1d1d;
  backdrop-filter: blur(10px);
}

.confetti {
  padding: 0 25px;
  height: 220px;
  margin-left: 40%;
  margin-top: 50px;
  backdrop-filter: blur(25px);
}

.popup-text {
  font-size: 27px;
  margin-left: 100px;
  margin-right: 100px;
  margin-top: 50px;
  color: #b4dad6;
  opacity: 90%;
}

.popup-button {
  margin-top: 50px;
  margin-left: 40%;
  padding: 10px 0px;
  border: 2px solid #4a978f;
  background-color: transparent;
  border-radius: 10px;
  width: 220px;
  cursor: pointer;
  font-size: 25px;
  color: #b4dad6;
  opacity: 90%;
}

.popup-button:hover {
  background-color: #0b4d46;
  filter: drop-shadow(3px 3px 3px #5db0a8);
  color: white;
  opacity: 100%;
}

.button-text {
  margin-left: 20px;
}

@media screen and (max-width: 900px) {
  .popup {
    margin-left: 10%;
    width: 80vw;
    height: 80vh;
  }

  .confetti {
    margin-left: 32%;
  }

  .popup-text {
    font-size: 25px;
    margin-left: 50px;
    margin-right: 50px;
  }

  .popup-button {
    margin-top: 20%;
    margin-left: 33%;
  }
}

@media screen and (max-width: 480px) {
  .popup {
    margin-left: 20px;
    width: 90vw;
    height: 75%;
  }

  .confetti {
    height: 100px;
    margin-left: 30%;
  }

  .popup-text {
    font-size: 18px;
    margin-left: 20px;
    margin-right: 20px;
  }

  .popup-button {
    margin-left: 26%;
    width: 150px;
    font-size: 15px;
    align-items: center;
    margin-top: 20px;
  }
}
