    .footer {
    background-color: #0b4d46;
    height: 20%;
  }

  .footer-icons {
    width: 20%;
    margin-left: 40%;
    padding-top: 40px;
    display: flex;
    justify-content: space-between;
  }

  .footer-text{
    align-items: center;
    margin-left: 46%;
    margin-top: 15px;
  }

  a {
    color: inherit; /* blue colors for links too */
    text-decoration: inherit; /* no underline */
  }
   
  @media screen and (max-width: 900px) {
    .footer-icons {
      width: 50%;
      padding-top: 10px;
      display: flex;
      justify-content: space-between;
    }

    .footer-text{
      align-items: center;
      margin-left: 42%;
      margin-top: 15px;
    }
  }
  
  @media screen and (max-width: 550px) {
    .footer {
      height: 15%;
    }
    .footer-icons {
      width: 50%;
      flex-direction: row;
      margin-left: 27%;
    }
    .footer-text {
      margin-left: 35%;
      margin-top: 0;
    }
  }
  
