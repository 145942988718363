.contact-us {
  height: 100%;
  width: 100vw;
  background-image: linear-gradient(to bottom, #000, #1e1e1e);
  color: white;
}

.contact-content {
  display: flex;
  height: 80%;
}

.contact-us-text {
  padding-top: 175px;
  margin-left: 150px;
  font-weight: 800;
  font-size: 45px;
  color: #2d7f77;
}

.icon {
  height: 30px;
}

.mail-icon {
  width: auto;
}

.contact {
  color: #b4dad6;
  opacity: 90%;
  align-items: center;
  font-size: 21px;
  display: flex;
  margin: 50px;
  margin-left: 150px;
  margin-right: 0px;
}

.phone-info {
  margin-left: 60px;
}

.mail-info {
  margin-left: 50px;
}

.address-info {
  margin-left: 60px;
}

.footer {
  background-color: #0b4d46;
  height: 20%;
}

.puzzle {
  width: 40%;
  height: 500px;
  margin-top: 50px;
  margin-left: 90px;
  cursor: pointer;
}

@media screen and (max-width: 900px) {
  .contact-content {
    flex-direction: column;
    height: 90%;
  }

  .contact-infos{
    height: 50%;
  }

  .contact-us-text {
    margin-left: 50px;
    margin-top: 0px;
    font-size: 35px;
  }

  .contact {
    margin-left: 50px;
    margin-right: 0;
  }

  .puzzle {
    height: 500px;
    width: 575px;
    margin-top: 0;
    margin-left: 100px;
  }
}

@media screen and (max-width: 550px) {

  .contact-us-text {
    margin-left: 20px;
    padding-top: 50px;
    font-size: 30px;
  }

  .contact {
    margin-left: 20px;
    margin-right: 0;
    margin-top: 10px;
    font-size: 18px;
  }

  .contact-infos{
    height: 40%;
  }

  .phone-info {
    margin-left: 20px;
  }

  .mail-info {
    margin-left: 15px;
  }

  .address-info {
    margin-left: 20px;
    font-size: 16px;
  }

  .contact-content {
    height: 75%;
    padding: 75px 10px 0;
  }

  .puzzle{
    display: none;
  }

  .icon{
    height: 1rem;
  }
}

@media (max-width: 550px) and (min-height: 900px) {
  .puzzle{
    display: contents;
    margin-top: 0;
  }
}

@media (max-height: 635px) {
  .contact-us-text {
    padding-top: 100px;
    font-size: 30px;
  }

  .contact {
    margin-top: 20px;
    margin-left: 20px;
    font-size: 15px;
  }

  .phone-info {
    margin-left: 15px;
  }

  .mail-info {
    margin-left: 10px;
  }

  .address-info {
    margin-left: 15px;
    font-size: 16px;
  }

  .contact-content {
    height: 80%;
  }
}

@media (max-height: 700px) and (max-width: 900px) {
  .contact-us-text {
    margin-left: 50px;
    padding-top: 150px;
    font-size: 30px;
  }

  .contact-content {
    height: 90%;
  }

  .contact-infos {
    height: 50%;
  }

  .contact {
    margin-left: 50px;
    margin-right: 0;
  }

  .puzzle {
    height: 500px;
    width: 575px;
    margin-top: 0;
    margin-left: 100px;
  }
}

@media (min-height: 500px) and (max-height: 650px) and (min-width: 1000px) {
  .contact-us-text {
    margin-left: 150px;
    padding-top: 175px;
    font-size: 30px;
  }

  .contact-content {
    height: 80%;
  }

  .contact{
    margin-left: 150px;
    font-size: 18px;
  }

  .puzzle {
    display: block;
    width: 40%;
    height: 500px;
    margin-top: 50px;
    margin-left: 350px;
  }

  .icon{
    height: 20px;
  }
}

@media (max-width: 700px) and (min-height: 800px) {
  .contact-us-text {
    margin-left: 20px;
    padding-top: 50px;
    font-size: 30px;
  }

  .contact-content {
    height: 75%;
  }

  .contact {
    margin-left: 20px;
    margin-right: 0;
    margin-top: 10px;
    font-size: 18px;
  }

  .contact-infos {
    height: 40%;
  }

  .phone-info {
    margin-left: 20px;
  }

  .mail-info {
    margin-left: 15px;
  }

  .address-info {
    margin-left: 20px;
    font-size: 16px;
  }

  .puzzle {
    display: none;
  }
}

@media (max-width: 550px) and (min-height: 900px) {
  .puzzle {
    display: contents;
    margin-top: 0;
  }
}


