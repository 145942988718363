@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap');

@font-face {
  font-family: "ARLRdBD";
  src: local("ARLRDBD"),
    url("./fonts/ARLRDBD.ttf") format("opentype");
  font-weight: normal;
}

body{
  height : 100vh;
  font-family: 'Open Sans',sans-serif ;
  color:white;
  overflow: hidden;
  font-weight: 800;
}
.container-height{
  height: 100vh;
}
.container-height.container{
  overflow-y: scroll;
  overflow-x: hidden; 
  scroll-snap-type: y mandatory;
  scroll-snap-align: start;
  scroll-snap-stop: always;
} 

.section{
  height: 100vh;
  scroll-snap-align: start;
  scroll-snap-stop: always;
}

.sections.blur{
  filter: blur(2px)
}
